import React from "react";
import { Carousel } from "react-bootstrap";
import { CarouselType } from "../../../../types/carousel.types";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./home.css";
import { Content } from "antd/es/layout/layout";
import { Card, Col, Row, theme } from "antd";
import { FaGamepad } from "react-icons/fa";
import { MdOutlineConnectWithoutContact, MdTouchApp } from "react-icons/md";
import { GiWireframeGlobe } from "react-icons/gi";

const slides: CarouselType[] = [
    {
        alt: "Cloud Ball",
        jsx: (
            <iframe width="100%" height="720" src="https://www.youtube.com/embed/xNiBEh6OG5M"
                title="Cloud Ball"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen>

            </iframe>
        ),
        title: "Cloud Ball",
        subTitle: "Disponível na Play Store."
    },
];

const gridStyle: React.CSSProperties = {
    textAlign: 'center',
};

export const HomeScreen = () => {

    const {
        token: { colorText },
    } = theme.useToken();

    return (
        <Content>

            <div className="row g-0">
                <Carousel pause={"hover"} fade>
                    {slides.map((values, index) => {
                        return (
                            <Carousel.Item key={index}>

                                <Row justify={"center"}>
                                    <Col className="text-center" span={24}>
                                        {values.jsx}
                                    </Col>
                                </Row>

                                {(values.title.length !== 0) && (
                                    <Carousel.Caption
                                        className="cousel-caption"
                                    >
                                        <div>
                                            <h4>
                                                <strong>
                                                    {values.title}
                                                </strong>
                                            </h4>
                                            <p>
                                                <strong>
                                                    {values.subTitle}
                                                </strong>
                                            </p>
                                        </div>
                                    </Carousel.Caption>
                                )}
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </div>

            <Row justify={"center"}>
                <Col span={24}>

                    <Card className="border-0">
                        <AnimationOnScroll
                            initiallyVisible={false}
                            animatePreScroll={false}
                            animateIn="animate__fadeIn"
                            animateOut="animate__fadeOut"
                        >

                            <Card className="border-0 mb-5 mt-5" hoverable={true} title={(
                                <Row style={{ color: colorText }} className="mt-5 text-center">
                                    <Col span={24}>
                                        <h2>
                                            <strong>
                                                Trabalhamos com diversas tecnologias!
                                            </strong>
                                        </h2>
                                    </Col>
                                </Row>
                            )}>
                                <Row>
                                    <Col span={24}>
                                        <Row>
                                            <Col className="w-100" md={8}>
                                                <Card.Grid className="w-100 h-100" style={gridStyle}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <GiWireframeGlobe size={70} />
                                                            <h3 className="text-color">
                                                                <strong>
                                                                    Desenvolvimento de
                                                                    Sites.
                                                                </strong>
                                                            </h3>
                                                            <h6>
                                                                Desde Landing page até site de vendas. Tudo com temas proprios,
                                                                para que você tenha exclusividade
                                                                em seu site.
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                </Card.Grid>
                                            </Col>
                                            <Col className="w-100" md={8}>
                                                <Card.Grid className="w-100 h-100" style={gridStyle}>
                                                    <Row >
                                                        <Col span={24}>

                                                            <MdTouchApp size={70} />
                                                            <h3 className="text-color">
                                                                <strong>
                                                                    Apps
                                                                </strong>
                                                            </h3>
                                                            <h6>
                                                                Aplicações Android e IOS
                                                            </h6>

                                                        </Col>
                                                    </Row>
                                                </Card.Grid>
                                            </Col>
                                            <Col className="w-100" md={8}>
                                                <Card.Grid className="w-100 h-100" style={gridStyle}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <FaGamepad size={70} />
                                                            <h3 className="text-color">
                                                                <strong>
                                                                    Games
                                                                </strong>
                                                            </h3>
                                                            <h6>
                                                                Fazemos jogos para diversas plataformas, incluindo Android e IOS.
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                </Card.Grid>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Card>
                        </AnimationOnScroll>

                        <AnimationOnScroll
                            initiallyVisible={false}
                            animatePreScroll={false}
                            animateIn="animate__fadeIn"
                            animateOut="animate__fadeOut"
                        >

                            <Card hoverable={true} className="m-4 mt-5 rounde-3">
                                <Row justify={"space-between"} className="text-white m-3">
                                    <Col md={14}>

                                        <h2>
                                            <strong>
                                                Tudo o que você precisa
                                            </strong>
                                        </h2>

                                        <h5>
                                            Fornecemos soluções em tecnologicas para organizar, agilizar e dar segurança
                                            nos seus processos
                                        </h5>

                                    </Col>
                                    <Col md={7}>

                                        <Row justify={"center"}>
                                            <Col span={24}>
                                                <MdOutlineConnectWithoutContact size={200} />
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                            </Card>

                        </AnimationOnScroll>
                    </Card>

                </Col>
            </Row >


        </Content >
    );


}
