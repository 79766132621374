import React, { useState } from "react";
import { Accordion, Offcanvas } from "react-bootstrap";
import { CgMenu } from "react-icons/cg";
import { MenuNavigation } from "../../../../types/includes/include.types";
import { UserDataLogged } from "../../../../types/user/user";
import { Logged } from "../../../logged.menu";
import { Login } from "../../screens/login";
import { MenuList } from "../menu.list/menu.list";

interface Props {
    navigateMenu: MenuNavigation[],
    dataUser: UserDataLogged
}

const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
    <a
        href="/"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        <strong>
            {children}
        </strong>
        <span style={{ fontSize: 10 }}>
            &nbsp; &#x25bc;
        </span>
    </a>
));

export const MenuMobileScreen = (prop: Props) => {

    const CustomUserMenu = React.forwardRef(
        ({ style, className, 'aria-labelledby': labeledBy }: any, ref: any) => (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                {
                    !prop.dataUser.token ?
                        <Login /> :
                        <Logged />
                }
            </div>
        ));

    function OffCanvasExample({ name, ...props }: any) {
        const [show, setShow] = useState(false);

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        return (
            <>
                <a href="/" onClick={(e) => {
                    e.preventDefault();
                    handleShow();
                }} className="me-2">
                    <CgMenu size={30} />
                </a>
                <Offcanvas
                    show={show}
                    onHide={handleClose}
                    {...props}
                >
                    <Offcanvas.Header
                        className="primary-background-color"
                        closeButton>
                        <Offcanvas.Title>
                            <div className="row">
                                <div className="col">
                                    <div className="row justify-content-start">
                                        <div className="col">
                                            {/*<Dropdown>
                                                <Dropdown.Toggle as={CustomToggle}>
                                                    {
                                                        prop.dataUser.token ?
                                                            <FaUserCircle size={40} /> :
                                                            <BiLogIn size={40} />
                                                    }
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu
                                                    className="col menu border-0"
                                                    style={{ backgroundColor: "transparent" }}
                                                    as={CustomUserMenu}
                                                >
                                                </Dropdown.Menu>

                                                </Dropdown>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="primary-background-color" >
                        <Accordion>
                            {
                                prop.navigateMenu.map((value, index) => {
                                    return (

                                        <Accordion.Item
                                            key={index}
                                            eventKey={index.toString()}>
                                            <Accordion.Header
                                                className="menu-mobile"
                                            >
                                                <strong>
                                                    {value.title}
                                                </strong>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {
                                                    value.subTitles.map((value, index) => {
                                                        return (
                                                            <MenuList
                                                                key={index}
                                                                href={value.href}
                                                                name={value.name}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    )
                                })
                            }
                        </Accordion>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }

    return (
        <>
            <OffCanvasExample placement={"end"} name={"Button"} />
        </>
    );

}
