import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { cookies } from "./controller/user/adm.cookies";
import { AppRoutes } from "./routes/routes";
import { UserDataLogged } from "./types/user/user";

const initialValues: UserDataLogged = {
    name: "",
    platformName: "",
    token: "",
    userType: "",
}

export const AppNavigation = () => {

    const [login, setLogin] = useState<UserDataLogged>(initialValues);

    useEffect(() => {

        const userData = cookies.get("data.user");

        const loginData = (userData.token) ? userData : initialValues;

        setLogin(loginData);
    }, []);

    return (
        <Router>
            <AppRoutes dataUser={login} />
        </Router>
    );

}
