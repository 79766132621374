import React from "react";
import { Route, Routes } from "react-router-dom";
import { About } from "../screens/about";
import { Contact } from "../screens/contact";
import { History } from "../screens/history";
import { Home } from "../screens/home";
import { Services } from "../screens/services";
import { Technology } from "../screens/technology";
import { WorkWithUs } from "../screens/work.with.us";
import { Footer } from "../includes/footer";
import { Header } from "../includes/head";
import { UserDataLogged } from "../../../types/user/user";
import { PrivacyPolicy } from "../screens/privacy.policy";


interface Props {
    dataUser: UserDataLogged
}

export const SiteRoutes = (props: Props) => {

    return (
        <>
            <Header dataUser={props.dataUser} />
            <Routes>
                <Route path="*" element={Home()} />
                { 
                /*<Route path="/technology" element={Technology()} />
                <Route path="/services" element={Services()} />
                <Route path="/contact" element={Contact()} />
                <Route path="/work-us" element={WorkWithUs()} />
                <Route path="/history" element={History()} />
                <Route path="/about" element={About()} />
                <Route path="/privacy-policy" element={PrivacyPolicy()} />*/
                }

            </Routes>
            <Footer />
        </>
    );

}
