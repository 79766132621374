import React from "react";
import { UserDataLogged } from "../../../../types/user/user";
import { HeaderScreen } from "./header.screen";


interface Props {
    dataUser: UserDataLogged
}

export const Header = (props: Props) => {

    return (
        <HeaderScreen dataUser={props.dataUser} />
    );

}