import React from "react";
import { HomeScreen } from "./home.screen";


export const Home = () => {

    return (
        <div className="col w-100">
            <HomeScreen />
        </div>
    );

}
