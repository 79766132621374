import { Col, Row, theme } from "antd";
import { Header } from "antd/es/layout/layout";
import React from "react";
import { Images } from "../../../../config/images";
import { UserDataLogged } from "../../../../types/user/user";
import { Menu } from "../menu";
import "./header.css";


interface Props {
    dataUser: UserDataLogged
}

export const HeaderScreen = (props: Props) => {

    const {
        token: { colorText, colorBgBase },
    } = theme.useToken();

    return (
        <Header className="p-0 mb-2" style={{ backgroundColor: colorBgBase }}>
            <Row justify={"space-between"} align={"top"}>
                <Col push={1} md={7} className="logo">
                    <Row>
                        <a href="/">
                            <Row>
                                <img
                                    className="img-logo"
                                    src={Images.logo} alt="Sua logo aqui."
                                />
                                <p style={{ color: colorText }}>
                                    <strong>
                                        Flathead Interactive
                                    </strong>
                                </p>
                            </Row>
                        </a>
                    </Row>
                </Col>
                <Col md={9}>
                    <Menu dataUser={props.dataUser} />
                </Col>
            </Row>
        </Header>
    );

}
