import React from "react";
import { AcceptCookiesScreen } from "./accept.cookies.screen";


export const AcceptCookies = () => {

    return (
        <AcceptCookiesScreen />
    );

}
