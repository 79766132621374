import React, { useEffect } from "react";
import { useState } from "react";
import { MenuNavigation } from "../../../../types/includes/include.types";
import { UserDataLogged } from "../../../../types/user/user";
import { MenuMobileScreen } from "./menu.mobile.screen";
import { MenuScreen } from "./menu.screen";
import "./menu.css";
import { Col, Row } from "antd";

interface Props {
    dataUser: UserDataLogged
}

const navigateMenu: MenuNavigation[] = [
    {
        title: "Corporativo",
        subTitles: [
            { name: "Quem Somos", href: "/about" },
            ///{ name: "Trabalhe conosco", href: "/work-us" },
        ]
    },
    {
        title: "Serviços",
        subTitles: [
            { name: "Nossos Serviços", href: "/services" },
            //{ name: "Tecnologia", href: "/technology" },
        ]
    },
    {
        title: "Sobre",
        subTitles: [
            { name: "Politica de privacidade", href: "/privacity-police" },
        ]
    },
];

export const Menu = (props: Props) => {

    const [screenMobile, setScreenMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            const sizeMobile: boolean = window.innerWidth < 1090;
            setScreenMobile(sizeMobile);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {
                !(screenMobile) && (
                    <Row align={"top"}>
                        <Col
                            md={24}
                        >
                            <MenuScreen
                                dataUser={props.dataUser}
                                navigateMenu={navigateMenu}
                            />
                        </Col>
                    </Row>
                )
            }

            {
                (screenMobile) && (
                    <Row justify={"center"} align="bottom">
                        <Col md={8}>
                            <MenuMobileScreen
                                dataUser={props.dataUser}
                                navigateMenu={navigateMenu}
                            />
                        </Col>
                    </Row>
                )
            }

        </>

    );

}