import React from "react";
import { UserDataLogged } from "../../types/user/user";
import { SiteRoutes } from "./routes/site.routes";

interface Props {
    dataUser: UserDataLogged
}

export const SiteNavigation = (props: Props) => {

    return (
        <SiteRoutes dataUser={props.dataUser} />
    );

}
