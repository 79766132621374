import axios from "../../config/axios";
import { User, UserClient, UserLogin } from "../../types/user/user";
import { Error } from "../errors/check.errors";
import { cookies } from "./adm.cookies";


export const UserController = {

    login: async (dataLogin: UserLogin) => {

        let request;
        let data;

        try {

            request = await axios.post("/login", dataLogin);

            data = request.data;

            const message = data.message;

            const user = data.user;

            cookies.store(user, "data.user");

            return { error: false, message };

        } catch (error: any) {

            const message = await Error.check(error);

            return { error: true, message };

        }

    },

    storePhysicalPerson: async (dataUser: User) => {

        const values = dataUser;

        try {

            const request = await axios.post("/user", values);

            const data = request.data;

            const message = data.message;

            return { error: false, message };

        } catch (error: any) {

            const message = await Error.check(error);

            return { error: true, message };

        }


    },

    storeClient: async (dataUser: UserClient) => {

        const values = dataUser;

        try {

            const request = await axios.post("/client", values);

            const data = request.data;

            const message = data.message;

            return { error: false, message };

        } catch (error: any) {

            const message = await Error.check(error);

            return { error: true, message };

        }


    },

    patchClient: async (dataUser: UserClient) => {

        const values = dataUser;

        try {

            const cookie = cookies.get("data.user");

            const token = cookie.token;

            const request = await axios.patch("/client", values, {
                headers: { 'authorization': `Bearer ${token}` }
            });

            const data = request.data;

            const message = data.message;

            return { error: false, message };

        } catch (error: any) {

            const message = await Error.check(error);

            return { error: true, message };

        }


    },

    patchPhysicalPerson: async (dataUser: User) => {

        const values = dataUser;

        try {

            const cookie = cookies.get("data.user");

            const token = cookie.token;

            const request = await axios.patch("/physical-person", values, {
                headers: { 'authorization': `Bearer ${token}` }
            });

            const data = request.data;

            const message = data.message;

            return { error: false, message };

        } catch (error: any) {

            const message = await Error.check(error);

            return { error: true, message };

        }


    },

    getStatusDelivery: async (deliveryCode: string) => {

        let request;
        let data;

        try {

            request = await axios.get(`/order/${deliveryCode}`);

            data = request.data;

            const message = data.message;

            return { error: false, message, data: data.data };

        } catch (error: any) {

            const message = await Error.check(error);

            return { error: true, message };

        }


    },

    getCollaborators: async () => {

        try {

            const cookie = cookies.get("data.user");

            const token = cookie.token;

            const request = await axios.get(`/collaborators`, {
                headers: { 'authorization': `Bearer ${token}` }
            });

            const data = request.data;

            const message = data.message;

            return { error: false, message, data: data.data };

        } catch (error: any) {

            const message = await Error.check(error);

            return { error: true, message };

        }


    },

    getCustomers: async () => {

        try {

            const cookie = cookies.get("data.user");

            const token = cookie.token;

            const request = await axios.get(`/customers`, {
                headers: { 'authorization': `Bearer ${token}` }
            });

            const data = request.data;

            const message = data.message;

            return { error: false, message, data: data.data };

        } catch (error: any) {

            const message = await Error.check(error);

            return { error: true, message };

        }


    },

    getClients: async () => {

        try {

            const cookie = cookies.get("data.user");

            const token = cookie.token;

            const request = await axios.get(`/client`, {
                headers: { 'authorization': `Bearer ${token}` }
            });

            const data = request.data;

            const message = data.message;

            return { error: false, message, data: data.data };

        } catch (error: any) {

            const message = await Error.check(error);

            return { error: true, message };

        }


    },

}

