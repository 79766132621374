import React from "react";
import { Route, Routes } from "react-router-dom";
import { SiteNavigation } from "../screens/site/site.navigation";
import { SystemNavigation } from "../screens/system/system.navigation";
import { UserDataLogged } from "../types/user/user";

interface Props {
    dataUser: UserDataLogged
}

export const AppRoutes = (props: Props) => {


    return isLogged();


    function isLogged() {

        return (
            <Routes>
                <Route path="*" element={SiteNavigation({ dataUser: props.dataUser })} />
                {/*<Route path="/system/*" element={SystemNavigation({ dataUser: props.dataUser })} />*/}
            </Routes>
        );

    }

}
